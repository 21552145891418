import { useEffect, useReducer } from "react";
import axios from 'axios';

import { skillReducer, initialState, actionTypes } from '../reducers/skillReducer';

export const useSkills = () => {
  const [state, dispatch] = useReducer(skillReducer, initialState);

  useEffect(() => {
    dispatch({ type: actionTypes.fetch });
    axios.get('https://api.github.com/users/wisetlaloc/repos')
      .then((response) => {
        const languageList = response.data.map(res => res.language);
        dispatch({ type: actionTypes.success, payload: { languageList: countedLanguageList(languageList) } });
      })
      .catch(() => {
        dispatch({ type: actionTypes.error });
      });
  }, []);

  const countedLanguageList = (languageList) => {
    const counter = languageList.reduce((counter, language) => {
      if (language === null) { return counter; }
      if (counter[language]) {
        counter[language]++
        return counter;
      }
      counter[language] = 1;
      return counter;
    }, {})
    return Object.entries(counter).map(([language, count]) => {
      return { language: language, count: count }
    });
  };

  const MAX_PERCENTAGE = 100;
  const FULL_REPO_COUNT = 10;

  const repoCountToPercentage = (repos) => {
    if (repos > FULL_REPO_COUNT) { return MAX_PERCENTAGE; }
    return repos * FULL_REPO_COUNT;
  };

  const sortedLanguageList = () => (
    state.languageList.sort((prev, current) => current.count - prev.count)
  )

  return [sortedLanguageList, state.requestState, repoCountToPercentage];
}

export const Contact = () => {
  return (
    <div id='contact' className='contact'>
      <div className='container'>
        <div className='container-contents'>
          <h2>Contact Me!</h2>
          <p className='description'>お仕事のご相談・ご依頼など下記よりご連絡ください。</p>
          <a href='https://twitter.com/wisetlaloc' className='btn'>Contact Me</a>
        </div>
      </div>
    </div>
  )
}

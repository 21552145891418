import CoverImage from '../images/background.jpg'
import ProfileImage from '../images/profile.jpg'
import { FaTwitter, FaGithub } from 'react-icons/fa'

export const Header = () => {
  return (
    <header className='main-cover' style={{ backgroundImage: `url(${CoverImage})` }}>
      <div className='overlay'></div>
      <div className='container'>
        <div className='display-table'>
          <div className='display-table-contents'>
            <div className='profile-thumb' style={{ backgroundImage: `url(${ProfileImage})` }}></div>
            <h1 className='title-text'>Kosuke Kushizaki</h1>
            <h3 className='title-text'>Ruby on Rails Engineer</h3>
            <ul className='social-icons'>
              <li className='icon-link'>
                <a href='https://twitter.com/wisetlaloc'>
                  <FaTwitter color='white' size='2rem' />
                </a>
                <a href='https://github.com'>
                  <FaGithub color='white' size='2rem' />
                </a>
              </li>
              <li className='icon-link'></li>
            </ul>
          </div>
        </div>
      </div>
    </header >
  );
};
